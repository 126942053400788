import Bullhorn from "./Bullhorn";
import Erecruit from "./Erecruit";
// import GreenhouseV2 from "./GreenhouseV2";
import GreenhouseV3 from "./GreenhouseV3";
import Herefish from "./Herefish";
import Hubspot from "./Hubspot";
import JobAdder from "./JobAdder";
import MsDynamics from "./MsDynamics";
import Salesforce from "./Salesforce";
import Webhook from "./Webhook";
import Workday from "./Workday";
import BlueSkyLogo from "utils/images/logo-blue-sky.jpg";
import BullhornLogo from "utils/images/logo-bullhorn.png";
import ErecruitLogo from "utils/images/logo-erecruit.png";
// import GreenhouseV2Logo from "utils/images/logo-greenhousev2.jpg";
import GreenhouseV3Logo from "utils/images/logo-greenhousev3.png";
import HerefishLogo from "utils/images/logo-herefish.png";
import HubspotLogo from "utils/images/Logo_OneLine_HubSpot_CRM.svg";
import JobAdderLogo from "utils/images/logo-jobadder.png";
import JobDivaLogo from "utils/images/logo-job-diva.png";
import LaborEdgeLogo from "utils/images/logo-labor-edge.png";
import MsDynamicsLogo from "utils/images/logo-ms-dynamics.png";
import SalesforceLogo from "utils/images/logo-salesforce.png";
import SymplrLogo from "utils/images/logo-symplr.svg";
import WorkdayLogo from "utils/images/logo-workday.png";

/**
 * Integration providers modal component routing
 *
 * Providers are in alphabetic order by name
 * @link https://app.shortcut.com/textus/story/53416/alphabetize-the-textus-integrations
 */
export const Providers = [
  {
    id: "blue_sky",
    name: "BlueSky",
    logo: BlueSkyLogo,
    component: Webhook,
  },
  {
    id: "bullhorn",
    name: "Bullhorn",
    logo: BullhornLogo,
    component: Bullhorn,
  },
  {
    id: "erecruit",
    name: "Erecruit",
    logo: ErecruitLogo,
    component: Erecruit,
  },
  /*
    Greenhouse V2 is commented out below as we want to hide the integration card from the integrations settings screen.
    The decision hasn't been made to fully remove the integration from the system, so we're keeping it commented out for now.
  */
  // {
  //   id: "greenhouse_v2",
  //   name: "Greenhouse V2",
  //   logo: GreenhouseV2Logo,
  //   component: GreenhouseV2,
  // },
  {
    id: "greenhouse",
    name: "Greenhouse V3",
    logo: GreenhouseV3Logo,
    component: GreenhouseV3,
  },
  {
    id: "herefish",
    name: "Herefish",
    logo: HerefishLogo,
    component: Herefish,
  },
  {
    id: "hubspot",
    name: "Hubspot",
    logo: HubspotLogo,
    component: Hubspot,
  },
  {
    id: "job_adder",
    name: "JobAdder",
    logo: JobAdderLogo,
    component: JobAdder,
  },
  {
    id: "job_diva",
    name: "JobDiva",
    logo: JobDivaLogo,
    component: Webhook,
  },
  {
    id: "labor_edge",
    name: "LaborEdge",
    logo: LaborEdgeLogo,
    component: Webhook,
  },
  {
    id: "dynamics",
    name: "MS Dynamics",
    logo: MsDynamicsLogo,
    component: MsDynamics,
  },
  {
    id: "salesforce",
    name: "Salesforce",
    logo: SalesforceLogo,
    component: Salesforce,
  },
  {
    id: "healthcare_source",
    name: "symplrCTM",
    logo: SymplrLogo,
    component: Webhook,
  },
  {
    id: "workday",
    name: "Workday",
    logo: WorkdayLogo,
    component: Workday,
  },
];
