import { useState } from "react";
import Box from "@mui/material/Box";

import ContactUpload from "./components/ContactUpload";
import ConversationUpload from "./components/ConversationUpload";
import ConversationUploadDescription from "./components/Description";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import PageHeader from "components/Page/PageHeader";

interface ConversationImportProps {
  toggleNavbar: () => boolean;
}

function ConversationImport({ toggleNavbar }: ConversationImportProps) {
  const [step, setStep] = useState(1);

  return (
    <SettingsPageWrapper>
      <PageHeader title="Conversation Import" toggleSidebar={toggleNavbar} />
      <Box sx={{ overflowY: "auto" }} my="20px">
        <Box margin="0 auto" maxWidth="720px" px={{ xs: 2, sm: 4 }} py={0}>
          <ConversationUploadDescription />
        </Box>

        {step === 1 ? (
          <ContactUpload
            onSkip={() => {
              return setStep(2);
            }}
            startNewImport={() => {}}
          />
        ) : (
          <ConversationUpload
            onSkip={() => {
              return setStep(1);
            }}
          />
        )}
      </Box>
    </SettingsPageWrapper>
  );
}

export default ConversationImport;
