/* eslint-disable react/no-unstable-nested-components */
import { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";

import { useHistory, withRouter } from "react-router";
import { get, isEmpty, union } from "lodash";
import moment from "moment";
import { Check, HelpOutline } from "@mui/icons-material";
import { Campaign } from "@tesseract/core";
import { Box, BoxProps, Tooltip, Typography } from "@mui/material";
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from "@mui/x-data-grid-pro";
import {
  CampaignRecipientsBatchActions,
  CampaignRecipientsFailures,
} from "./components";
import withRecord from "higherOrderComponents/withRecord";
import { actionGenerators as composeActionGenerators } from "features/Compose/containers/ComposeRoot/state";
import Table from "components/Table";
import { useCurrentAccount } from "hooks";
import { TextUsTable } from "components/TextUsTable";

export type CampaignRecipientCollectionProps = {
  campaignRecipientCollection: Campaign.RecipientCollection;
  location: Location;
  match: {
    isExact: boolean;
    params: {
      [key: string]: string;
    };
    path: string;
    url: string;
  };
  setCompose: (...args: any) => void;
};

function IconWrapper({ children }: BoxProps) {
  return (
    <Box
      sx={{
        cursor: "help",
        marginLeft: "6px",
      }}
    >
      {children}
    </Box>
  );
}

export function CampaignRecipientCollection(
  props: CampaignRecipientCollectionProps,
) {
  const { campaignRecipientCollection, location, match, setCompose } = props;

  // ==== DATA GRID ==== //
  const columns: readonly GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridCellParams) => {
        return get(row, ["contactPhone", "contact", "name"]);
      },
    },
    {
      field: "number",
      headerName: "Number",
      flex: 1,
      sortable: false,
      valueGetter: ({ row }: GridCellParams) => {
        return get(row, ["contactPhone", "formattedPhoneNumber"]);
      },
    },
    {
      field: "sentAt",
      flex: 1,
      sortable: false,
      renderHeader() {
        const sentAtDate = get(campaignRecipientCollection, [
          "members",
          0,
          "sentAt",
        ]);
        const formattedDate = `(${moment(sentAtDate).format("L")})`;
        return (
          <Typography
            variant="body2"
            fontWeight={500}
          >{`Sent At ${(sentAtDate && formattedDate) || ""}`}</Typography>
        );
      },
      valueGetter: ({ row }: GridCellParams) => {
        const sentAt = get(row, ["sentAt"]);
        return sentAt ? moment(sentAt).format("hh:mm:ss a z") : "-";
      },
    },
    {
      field: "delivered",
      headerName: "Delivered",
      align: "center",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        const delivered = get(row, ["delivered"]);
        const unknown = get(row, ["unknown"]);
        const tooltipContent = "Did not receive a delivery confirmation";
        if (delivered) {
          return <Check />;
        }
        if (unknown) {
          return (
            <Tooltip title={tooltipContent} placement="bottom">
              <IconWrapper>
                <HelpOutline fontSize="medium" />
              </IconWrapper>
            </Tooltip>
          );
        }
        return "-";
      },
    },
    {
      field: "repliedIn",
      flex: 1,
      headerName: "Replied In",
      align: "center",
      headerAlign: "center",
      sortable: false,
      valueGetter: ({ row }: GridCellParams) => {
        const replyLag = get(row, ["replyLag"]);
        return replyLag ? moment.duration(replyLag).humanize() : "-";
      },
    },
    {
      field: "failure",
      flex: 1,
      headerClassName: "full-width",
      sortable: false,
      renderHeader() {
        return (
          <CampaignRecipientsFailures
            match={match}
            location={location}
            redesign
          />
        );
      },
      renderCell: ({ row }: GridRenderCellParams) => {
        const state = get(row, ["state"]);
        const error = get(row, ["errorDescription"]);

        if (state === "cutoff") {
          const tooltipContent =
            "Message not sent because delivery was attempted after the provided cutoff time";
          return (
            <Box>
              <Typography variant="body2">Cutoff</Typography>
              <Tooltip title={tooltipContent} placement="bottom">
                <IconWrapper>
                  <HelpOutline fontSize="medium" />
                </IconWrapper>
              </Tooltip>
            </Box>
          );
        }

        if (state === "canceled") {
          const tooltipContent =
            "Message not sent because campaign was canceled";
          return (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body2">Canceled</Typography>
              <Tooltip title={tooltipContent} placement="bottom">
                <HelpOutline fontSize="small" />
              </Tooltip>
            </Box>
          );
        }

        return error || "-";
      },
    },
    {
      field: "nameMobile",
      headerName: "Name",
      flex: 1,
      sortable: false,
      renderCell: ({ row }: GridRenderCellParams) => {
        const contactName = get(row, ["contactPhone", "contact", "name"]);

        const contactNumber = get(row, [
          "contactPhone",
          "formattedPhoneNumber",
        ]);

        return (
          <Box>
            <Typography variant="body2" color="text.primary">
              {contactName}
            </Typography>

            {contactNumber && (
              <Typography variant="caption" color="text.secondary">
                {contactNumber}
              </Typography>
            )}
          </Box>
        );
      },
    },
  ];

  const getColumns = () => {
    return [
      {
        title: "Name",
        primary: true,
        getTableCellContent: (campaignRecipient: Campaign.Recipient) => {
          return get(campaignRecipient, ["contactPhone", "contact", "name"]);
        },
        showContentInTooltip: true,
      },
      {
        title: "Number",
        getTableCellContent: (campaignRecipient: Campaign.Recipient) => {
          return get(campaignRecipient, [
            "contactPhone",
            "formattedPhoneNumber",
          ]);
        },
        nowrap: true,
      },
      {
        title: (() => {
          const sentAtDate = get(campaignRecipientCollection, [
            "members",
            0,
            "sentAt",
          ]);
          const formattedDate = `(${moment(sentAtDate).format("L")})`;
          return `Sent At ${(sentAtDate && formattedDate) || ""}`;
        })(),
        getTableCellContent: (campaignRecipient: Campaign.Recipient) => {
          const sentAt = get(campaignRecipient, ["sentAt"]);
          return sentAt ? moment(sentAt).format("hh:mm:ss a z") : "-";
        },
        showContentInTooltip: true,
      },
      {
        title: "Delivered",
        align: "center",
        getTableCellContent: (campaignRecipient: Campaign.Recipient) => {
          const delivered = get(campaignRecipient, ["delivered"]);
          const unknown = get(campaignRecipient, ["unknown"]);
          const tooltipContent = "Did not receive a delivery confirmation";
          if (delivered) {
            return <Check />;
          }
          if (unknown) {
            return (
              <Tooltip title={tooltipContent} placement="bottom">
                <IconWrapper>
                  <HelpOutline fontSize="medium" />
                </IconWrapper>
              </Tooltip>
            );
          }
          return "-";
        },
      },
      {
        title: "Replied In",
        align: "center",
        getTableCellContent: (campaignRecipient: Campaign.Recipient) => {
          const replyLag = get(campaignRecipient, ["replyLag"]);
          return replyLag ? moment.duration(replyLag).humanize() : "-";
        },
      },
      {
        title: (() => {
          return (
            <CampaignRecipientsFailures match={match} location={location} />
          );
        })(),
        getTableCellContent: (campaignRecipient: Campaign.Recipient) => {
          const state = get(campaignRecipient, ["state"]);
          const error = get(campaignRecipient, ["errorDescription"]);
          if (state === "cutoff") {
            const tooltipContent =
              "Message not sent because delivery was attempted after the provided cutoff time";
            return (
              <div>
                <span>Cutoff</span>
                <Tooltip title={tooltipContent} placement="bottom">
                  <IconWrapper>
                    <HelpOutline fontSize="medium" />
                  </IconWrapper>
                </Tooltip>
              </div>
            );
          }
          if (state === "canceled") {
            const tooltipContent =
              "Message not sent because campaign was canceled";
            return (
              <div>
                <span>Canceled</span>
                <Tooltip title={tooltipContent} placement="bottom">
                  <IconWrapper>
                    <HelpOutline fontSize="medium" />
                  </IconWrapper>
                </Tooltip>
              </div>
            );
          }
          return error || "-";
        },
        showContentInTooltip: true,
      },
    ];
  };

  const getCollapsedColumns = () => {
    return [
      {
        title: "Contact",
        getTableCellContent: (campaignRecipient: Campaign.Recipient) => {
          const contactName = get(campaignRecipient, [
            "contactPhone",
            "contact",
            "name",
          ]);
          const contactNumber = get(campaignRecipient, [
            "contactPhone",
            "formattedPhoneNumber",
          ]);
          return (
            <div>
              <Typography
                sx={{
                  color: "primary.main",
                  fontWeight: 700,
                }}
              >
                {contactName}
              </Typography>

              {contactNumber && (
                <Typography
                  sx={{
                    color: "text.primary",
                    fontSize: "0.75rem",
                  }}
                >
                  {contactNumber}
                </Typography>
              )}
            </div>
          );
        },
      },
    ];
  };

  // ==== HOOKS ==== //
  const [allSelected, setAllSelected] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedRecords, setSelectedRecords] = useState<Campaign.Recipient[]>(
    [],
  );
  const [
    previousCampaignRecipientCollectionId,
    setPreviousCampaignRecipientCollectionId,
  ] = useState<string | null>(null);

  const currentAccount = useCurrentAccount();
  const history = useHistory();

  const { campaignsResponsiveness = false } = currentAccount.featureFlags;

  useEffect(() => {
    if (
      allSelected &&
      previousCampaignRecipientCollectionId !== campaignRecipientCollection.id
    ) {
      const visibleIds = get(campaignRecipientCollection, ["members"], []).map(
        (recipient) => {
          return recipient.id;
        },
      );

      setSelected(union(selected, visibleIds));
    }

    return () => {
      setPreviousCampaignRecipientCollectionId(campaignRecipientCollection.id);
    };
  }, [
    allSelected,
    campaignRecipientCollection,
    previousCampaignRecipientCollectionId,
    selected,
  ]);

  // ==== METHODS ==== //
  const handleSetAllSelected = (allSelected_: boolean) => {
    const visibleIds = get(campaignRecipientCollection, ["members"], []).map(
      (recipient) => {
        return recipient.id;
      },
    );

    setSelected(allSelected_ ? union(selected, visibleIds) : []);
    setAllSelected(allSelected_);
    setSelectedRecords([]);
  };

  const handleSetSelected = (selected_: string[]) => {
    setAllSelected(false);
    setSelected(selected_);
  };

  const handleSetSelectedRecords = (selected_: Campaign.Recipient[]) => {
    setAllSelected(false);
    setSelectedRecords(selected_);
  };

  const handleSetCompose = () => {
    const recipientIds = selected.map((selectedId) => {
      return campaignRecipientCollection.members.find((recipient) => {
        return recipient.id === selectedId;
      })?.contactPhone.id;
    });

    setCompose({ active: true, recipientIds });
  };

  // ==== RENDER ==== //
  return (
    <Box
      sx={(theme) => {
        return {
          border: "1px solid",
          borderColor: theme.palette.divider,
          display: "flex",
          flexDirection: "column",
          overflowX: "auto",
          position: "relative",
          width: "100%",
        };
      }}
    >
      {!isEmpty(campaignRecipientCollection) && selected.length > 0 && (
        <CampaignRecipientsBatchActions
          allSelected={allSelected}
          currentAccount={currentAccount}
          handleSetCompose={handleSetCompose}
          left={campaignsResponsiveness ? "50px" : "64px"}
          selected={selected}
          selectedRecords={selectedRecords}
          setAllSelected={handleSetAllSelected}
          setSelected={handleSetSelected}
          setSelectedRecords={handleSetSelectedRecords}
          {...props}
        />
      )}
      {campaignsResponsiveness ? (
        <TextUsTable
          className="campaign-recipients-collection-table"
          collection={campaignRecipientCollection}
          columns={columns}
          hideColumnsOnMobile={[
            "name",
            "number",
            "sentAt",
            "delivered",
            "repliedIn",
            "failure",
          ]}
          hideColumnsOnDesktop={["nameMobile"]}
          selected={selected}
          setSelected={handleSetSelected}
          setSelectedRecords={handleSetSelectedRecords}
        />
      ) : (
        <Table
          collapsedColumns={getCollapsedColumns()}
          columns={getColumns()}
          collection={campaignRecipientCollection}
          selected={selected}
          selectedRecords={selectedRecords}
          setSelected={handleSetSelected}
          setSelectedRecords={handleSetSelectedRecords}
          withBatchActions
        />
      )}
    </Box>
  );
}

const withConnect = connect(null, {
  setCompose: composeActionGenerators.setCompose,
});

export default compose(
  withRecord({
    actions: ["fetch", "subscribe", "unsubscribe", "block", "unblock"],
    container: "CampaignRecipientCollection",
    shape: { members: [{ contactPhone: { contact: {} } }] },
    showLoader: () => {
      return false;
    },
    type: "campaignRecipientCollection",
  }),
  withConnect,
  withRouter,
)(CampaignRecipientCollection);
