import { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { Formik, Field, Form } from "formik";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { compose } from "redux";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";

import { actionGenerators, saga } from "./state";
import { BasicProfileValidationSchema } from "formHelpers/validationSchemas";
import { Dropzone } from "components/Dropzone";
import Icon from "components/Icon";
import ICONS from "constants/icons";
import injectSaga from "utils/injectSaga";
import Loader from "components/Loader";
import PageHeader from "components/Page/PageHeader";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import userAvatarUrl from "utils/userAvatarUrl";
import withProfileSubmit from "higherOrderComponents/withProfileSubmit";

const Avatar = styled.div`
  display: block;
  width: 200px;
  height: 200px;
  margin: 0 auto 20px auto;
  background-image: url(${(props) => {
    return props.src;
  }});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 50%;
`;

const StyledUploadZone = styled(Dropzone)`
  width: 200px;
  height: 200px;
  margin: 0 auto 20px auto;

  > div {
    border-radius: 50%;
    border-style: solid;
  }

  p {
    margin-bottom: 0;
  }
`;

const ButtonUploadZone = styled(Dropzone)`
  > div {
    border: none !important;
    padding: 0;
  }
`;

class BasicProfile extends Component {
  static propTypes = {
    currentAccount: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    isMediumLarge: PropTypes.bool.isRequired,
    toggleSidebar: PropTypes.func.isRequired,
    uploadAvatarRequest: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      isUploading: false,
      temporaryUrl: null,
      defaultMessagingAccount: get(
        props,
        ["currentUser", "settings", "defaultMessagingAccount"],
        "",
      ),
    };
  }

  handleFilesAccepted = (setFieldValue) => {
    return (file) => {
      const { currentAccount, currentUser, uploadAvatarRequest } = this.props;
      this.setState({
        isUploading: true,
        temporaryUrl: URL.createObjectURL(file),
        rejectedFile: false,
        rejectedFileError: "",
      });
      uploadAvatarRequest(
        currentAccount.signedAvatarUrl,
        {
          file,
          user: currentUser.id,
        },
        {
          successCallback: (response) => {
            setFieldValue("avatar", response.key);
            this.setState({ isUploading: false });
          },
        },
      );
    };
  };

  handleChange = ({ target: { value } }) => {
    this.setState({ defaultMessagingAccount: value });
  };

  render() {
    const { currentUser, isMediumLarge, toggleSidebar } = this.props;
    const { isUploading } = this.state;
    const members = get(currentUser, ["memberships", "members"], []);
    const messagingAccounts = members.filter(
      ({ account: { phoneNumbers } }) => {
        return !isEmpty(phoneNumbers);
      },
    );
    const initialValues = {
      avatar: get(currentUser, ["settings", "avatar"]),
      email: currentUser.email,
      name: currentUser.name,
      defaultMessagingAccount: this.state.defaultMessagingAccount || undefined,
    };
    return (
      <SettingsPageWrapper>
        <PageHeader title="Basic Profile" toggleSidebar={toggleSidebar} />
        <SettingsPageContent isMediumLarge={isMediumLarge}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            isInitialValid={BasicProfileValidationSchema.isValidSync(
              initialValues,
            )}
            validationSchema={BasicProfileValidationSchema}
            onSubmit={this.props.handleSubmit}
          >
            {({
              errors,
              isSubmitting,
              isValid,
              setFieldValue,
              touched = {},
              values,
            }) => {
              const uploadProps = {
                acceptedFileTypes: "image/jpeg, image/png, image/gif",
                maxFileSize: 5_000_000,
                onFileSelected: () => {},
                onFileAccepted: this.handleFilesAccepted(setFieldValue),
                onFileRejected: (rejectedFile) => {
                  this.setState({
                    rejectedFile: true,
                    rejectedFileError: rejectedFile[0].errors[0].message,
                  });
                },
              };
              return (
                <Form>
                  <Box
                    display="flex"
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <Box
                      alignItems="center"
                      display="flex"
                      flex="0 0 auto"
                      flexDirection="column"
                    >
                      {get(values, ["avatar"]) ? (
                        <Avatar
                          src={
                            this.state.temporaryUrl ||
                            userAvatarUrl(currentUser, 200)
                          }
                        />
                      ) : (
                        <StyledUploadZone {...uploadProps}>
                          <Icon icon={ICONS.PHOTO} iconSize="40" />
                          <p data-testid="upload-text">Drop image to upload</p>
                        </StyledUploadZone>
                      )}
                      <ButtonUploadZone {...uploadProps}>
                        <Button
                          data-testid="upload-image-button"
                          color="primary"
                          type="button"
                          variant="outlined"
                        >
                          <Loader isLoading={isUploading}>
                            {get(values, ["avatar"])
                              ? "Change image"
                              : "Select image"}
                          </Loader>
                        </Button>
                      </ButtonUploadZone>
                      {this.state.rejectedFile ? (
                        <Box
                          color="red"
                          mt={2}
                          textAlign="center"
                          width="250px"
                        >
                          {this.state.rejectedFileError}
                        </Box>
                      ) : (
                        <Box mt={2} textAlign="center" width="250px">
                          Please upload a file of type jpeg, png, or gif. File
                          size limit is 5MB.
                        </Box>
                      )}
                    </Box>
                    <Box
                      display="flex"
                      flex="1 1 auto"
                      flexDirection="column"
                      mt={{ xs: "40px", sm: "0px" }}
                      ml={{ sm: "40px" }}
                      p={1.5}
                    >
                      <Box flex="0 0 auto" mb={4}>
                        <Field type="text" name="name">
                          {({ field }) => {
                            return (
                              <TextField
                                {...field}
                                autoFocus
                                error={touched.name && Boolean(errors.name)}
                                fullWidth
                                helperText={touched.name && errors.name}
                                id="Profile-BasicProfile-name"
                                inputProps={{ "data-lpignore": true }}
                                label="Your Name"
                                placeholder="John Smith"
                                type="text"
                                variant="outlined"
                              />
                            );
                          }}
                        </Field>
                      </Box>
                      <Box flex="0 0 auto" mb={4}>
                        <Field type="email" name="email">
                          {({ field }) => {
                            return (
                              <TextField
                                {...field}
                                disabled
                                error={touched.email && Boolean(errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                id="Profile-BasicProfile-email"
                                inputProps={{ "data-lpignore": true }}
                                label="Your Email"
                                placeholder="john@example.com"
                                type="text"
                                variant="outlined"
                              />
                            );
                          }}
                        </Field>
                      </Box>
                      {messagingAccounts.length > 1 && (
                        <Box flex="0 0 auto" mb={4}>
                          <Field type="select" name="defaultAccount">
                            {({ field }) => {
                              return (
                                <FormControl fullWidth variant="outlined">
                                  <InputLabel htmlFor="Profile-BasicProfile-defaultMessagingAccount">
                                    Default Account
                                  </InputLabel>
                                  <Select
                                    {...field}
                                    value={this.state.defaultMessagingAccount}
                                    label="Default Account"
                                    onChange={this.handleChange}
                                    inputProps={{
                                      name: "default account",
                                      id: "Profile-BasicProfile-defaultMessagingAccount",
                                    }}
                                    variant="standard"
                                  >
                                    <MenuItem value="" disabled>
                                      No Default Account Set
                                    </MenuItem>
                                    {messagingAccounts.map(
                                      ({ account: { name, slug } }) => {
                                        return (
                                          <MenuItem
                                            data-testid={slug}
                                            value={slug}
                                            key={slug}
                                          >
                                            {name}
                                          </MenuItem>
                                        );
                                      },
                                    )}
                                  </Select>
                                </FormControl>
                              );
                            }}
                          </Field>
                        </Box>
                      )}
                      <Box
                        display="flex"
                        flex="0 0 auto"
                        justifyContent="flex-end"
                        mt={{ xs: "0px", sm: "40px" }}
                      >
                        <Button
                          aria-label="Save"
                          data-testid="save-button"
                          color="primary"
                          disabled={!isValid || isSubmitting || isUploading}
                          type="submit"
                          variant="contained"
                        >
                          <Loader isLoading={isSubmitting}>Save</Loader>
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </SettingsPageContent>
      </SettingsPageWrapper>
    );
  }
}

const withConnect = connect(() => {
  return {};
}, actionGenerators);

const withSaga = injectSaga({ key: "AttachmentsOptionContainer", saga });

export default compose(withSaga, withConnect, withProfileSubmit)(BasicProfile);
