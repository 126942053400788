import { useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormHelperText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Recipients from "../../../sharedComponents/Recipients";
import RecipientsExclusionSection from "../../../sharedComponents/RecipientsExclusionSection";
import Groups from "../Groups";
import { SelectRecipients } from "./SelectRecipients";
import { StepSection } from "./StepSection";
import { CampaignRecipientsProps } from "./types";
import NewCampaignContactFilter from "features/Campaigns/containers/NewCampaignContactFilter";
import NewCampaignContactCollection from "features/Campaigns/containers/NewCampaignContactCollection";

export default function CampaignRecipients({
  activeStep,
  campaignType,
  contactCollectionId,
  contactFilterId,
  contactImport,
  currentAccount,
  dailyCampaignRecipients,
  exclusionTimeInput,
  getRecipientCount,
  recipients,
  selectGroup,
  setCampaignRecipients,
  setGroup,
  setExclusionTimeInput,
  setSelectGroup,
}: CampaignRecipientsProps) {
  // ==== FIELDS ==== //
  const { campaignsResponsiveness = false } = currentAccount.featureFlags;
  const recipientCount = getRecipientCount();
  const headerText = `Recipients ${recipientCount || "0"}`;
  const error =
    recipientCount > dailyCampaignRecipients &&
    "Number of campaign recipients exceeds maximum daily limit for account";

  // ==== HOOKS ==== //
  // TODO: (Amanda Sands) Remove campaignsResponsiveness once Campaigns redesign work is live
  useEffect(() => {
    if (
      campaignType === "recurring" &&
      activeStep === 1 &&
      campaignsResponsiveness
    ) {
      setSelectGroup(true);
    }
  }, [activeStep, campaignType, campaignsResponsiveness, setSelectGroup]);
  const theme = useTheme();
  const mobileView = useMediaQuery(() => {
    return theme.breakpoints.down("sm");
  });

  // ==== METHODS ==== //
  const handleExclusionTimeChange = (timeInput: number) => {
    return setExclusionTimeInput(timeInput);
  };

  // ==== RENDERS ==== //
  const optOutMessage = (
    <>
      <b>Please note:</b> When this Campaign is sent, a message will not attempt
      to send to any number that has since opted out.
    </>
  );

  // Step 2: Select recipients > Select group
  if (selectGroup) {
    return (
      <StepSection campaignsResponsiveness={campaignsResponsiveness}>
        {campaignsResponsiveness ? (
          <Typography
            align="center"
            alignSelf="stretch"
            data-testid="text"
            style={{ fontSize: "1.5rem" }}
            variant="h4"
            paddingTop={mobileView ? "2rem" : "0rem"}
            paddingLeft="1rem"
            paddingRight="1rem"
            paddingBottom="0rem"
          >
            Select group
          </Typography>
        ) : (
          <Typography
            align="center"
            component="h2"
            sx={{ fontSize: "2.5rem" }}
            variant="h2"
          >
            Select Group
          </Typography>
        )}
        <Box my={campaignsResponsiveness ? "0rem" : "2rem"}>
          <Typography
            align="center"
            variant="body1"
            color="textSecondary"
            padding="1rem"
            paddingTop="0rem"
            paddingBottom={campaignsResponsiveness ? "2rem" : "0rem"}
          >
            Choose one group to receive the campaign.
          </Typography>
        </Box>
        <RecipientsExclusionSection
          exclusionTimeInput={exclusionTimeInput}
          handleExclusionTimeChange={handleExclusionTimeChange}
          campaignsResponsiveness={campaignsResponsiveness}
        />
        <Groups
          groupFilterCollectionId={
            currentAccount.groupFilters ||
            `/${currentAccount.slug}/group_filters`
          }
          currentAccount={currentAccount}
          setGroup={setGroup}
          setGroupRecipients={setCampaignRecipients}
          mobileView={mobileView}
        />
      </StepSection>
    );
  }

  if (recipients.length > 0) {
    return (
      <StepSection campaignsResponsiveness={campaignsResponsiveness}>
        <Typography variant="h3">{headerText}</Typography>
        {error && <FormHelperText error>{error}</FormHelperText>}
        <RecipientsExclusionSection
          exclusionTimeInput={exclusionTimeInput}
          handleExclusionTimeChange={handleExclusionTimeChange}
        />
        <Box mb={2} color="text.secondary" style={{ fontSize: "0.85rem" }}>
          {optOutMessage}
        </Box>
        <Recipients recipients={recipients} />
      </StepSection>
    );
  }

  if (
    contactFilterId ||
    (contactImport &&
      contactImport.contactFilter &&
      contactImport?.state === "processed")
  ) {
    const filterId =
      typeof contactImport?.contactFilter === "string"
        ? contactImport?.contactFilter
        : contactImport?.contactFilter?.id;
    const campaignContactFilterId = contactFilterId || filterId;

    return (
      <StepSection campaignsResponsiveness={campaignsResponsiveness}>
        <Typography variant="h3">{headerText}</Typography>
        {error && <FormHelperText error>{error}</FormHelperText>}
        <RecipientsExclusionSection
          exclusionTimeInput={exclusionTimeInput}
          handleExclusionTimeChange={handleExclusionTimeChange}
        />
        <Box mb={2} color="text.secondary" style={{ fontSize: "0.85rem" }}>
          {optOutMessage}
        </Box>
        <NewCampaignContactFilter
          contactFilterId={campaignContactFilterId}
          currentUrl={document.location.href}
        />
      </StepSection>
    );
  }

  if (contactCollectionId) {
    return (
      <StepSection campaignsResponsiveness={campaignsResponsiveness}>
        <Typography variant="h3">{headerText}</Typography>
        {error && <FormHelperText error>{error}</FormHelperText>}
        <RecipientsExclusionSection
          exclusionTimeInput={exclusionTimeInput}
          handleExclusionTimeChange={handleExclusionTimeChange}
        />
        <Box mb={2} color="text.secondary" style={{ fontSize: "0.85rem" }}>
          {optOutMessage}
        </Box>
        <NewCampaignContactCollection
          contactCollectionId={contactCollectionId}
        />
      </StepSection>
    );
  }

  // Step 2: Select recipients
  return (
    <SelectRecipients
      campaignType={campaignType}
      campaignsResponsiveness={campaignsResponsiveness}
      contactImport={contactImport}
      mode={theme.palette.mode}
      setSelectGroup={setSelectGroup}
    />
  );
}

CampaignRecipients.propTypes = {
  activeStep: PropTypes.number.isRequired,
  campaignType: PropTypes.string.isRequired,
  contactCollectionId: PropTypes.string,
  contactFilterId: PropTypes.string,
  contactImport: PropTypes.object,
  currentAccount: PropTypes.object.isRequired,
  dailyCampaignRecipients: PropTypes.number.isRequired,
  exclusionTimeInput: PropTypes.number,
  getRecipientCount: PropTypes.func.isRequired,
  recipients: PropTypes.array.isRequired,
  selectGroup: PropTypes.bool.isRequired,
  setCampaignRecipients: PropTypes.func.isRequired,
  setExclusionTimeInput: PropTypes.func.isRequired,
  setGroup: PropTypes.func.isRequired,
  setSelectGroup: PropTypes.func.isRequired,
};
