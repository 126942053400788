import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import get from "lodash/get";
import { Formik, Field, Form } from "formik";
import { normalize } from "normalizr";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import { selectCurrentUser } from "features/EntryPoint/containers/App/selectors";
import { SignatureValidationSchema } from "formHelpers/validationSchemas";
import { updateRecords as updateRecordsActionCreator } from "features/EntryPoint/containers/App/actions";
import * as schema from "schema";
import Loader from "components/Loader";
import PageHeader from "components/Page/PageHeader";
import SettingsPageWrapper from "components/SettingsPageComponents/SettingsPageWrapper";
import SettingsPageContent from "components/SettingsPageComponents/SettingsPageContent";
import SettingsDescription from "components/SettingsPageComponents/SettingsDescription";
import withRecord from "higherOrderComponents/withRecord";

const createErrorCallback = (actions) => {
  return (errors) => {
    const validationErrors = get(
      errors,
      ["validationErrors"],
      "Something went wrong!",
    );
    actions.setSubmitting(false);
    actions.setErrors(validationErrors);
  };
};

function Signature({
  createSignatureRequest,
  currentUser,
  deleteSignatureRequest,
  toggleSidebar,
  updateRecords,
  updateSignatureRequest,
}) {
  const signatureId = get(currentUser, ["signature", "id"]);
  const initialValues = {
    content: get(currentUser, ["signature", "content"], ""),
  };

  const updateUser = (response) => {
    const updatedUser = { id: currentUser.id, signature: response };
    const { entities } = normalize(updatedUser, schema.user);
    updateRecords(entities);
  };

  const handleDelete = () => {
    deleteSignatureRequest(signatureId, null, {
      successCallback: () => {
        updateUser(null);
      },
    });
  };

  const handleSubmit = (values, actions) => {
    const contentLength = get(values, ["content"], "").length;
    if (!signatureId && contentLength === 0) {
      actions.setSubmitting(false);
      return;
    }
    if (!signatureId && contentLength > 0) {
      createSignatureRequest(`${currentUser.id}/signature`, values, {
        successCallback: (response) => {
          updateUser(response);
          actions.setSubmitting(false);
        },
        errorCallback: createErrorCallback(actions),
      });
      return;
    }
    if (signatureId && contentLength === 0) {
      deleteSignatureRequest(signatureId, null, {
        successCallback: () => {
          updateUser(null);
          actions.setSubmitting(false);
        },
        errorCallback: createErrorCallback(actions),
      });
      return;
    }
    updateSignatureRequest(signatureId, values, {
      successCallback: () => {
        actions.setSubmitting(false);
      },
      errorCallback: createErrorCallback(actions),
    });
  };

  return (
    <SettingsPageWrapper>
      <PageHeader title="Signature" toggleSidebar={toggleSidebar} />
      <SettingsPageContent>
        <SettingsDescription>
          This is your personal signature. It will automatically be appended to
          your messages whenever you send a message to a contact for the first
          time or whenever you were not the last person to send a message to a
          contact. You can always manually turn your signature on or off from an
          individual conversation.
        </SettingsDescription>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          isInitialValid={SignatureValidationSchema.isValidSync(initialValues)}
          validationSchema={SignatureValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors = {}, touched = {}, isSubmitting, isValid }) => {
            return (
              <Form>
                <Field type="text" name="content">
                  {({ field }) => {
                    return (
                      <TextField
                        {...field}
                        fullWidth
                        variant="outlined"
                        multiline
                        rows="4"
                        id="Profile-Signature"
                        data-testid="profile-signature"
                        helperText={touched.description && errors.description}
                        label="Your Signature"
                        error={
                          touched.description && Boolean(errors.description)
                        }
                      />
                    );
                  }}
                </Field>
                <Box marginTop="40px" textAlign="right">
                  {Boolean(signatureId) && (
                    <Button
                      aria-label="Delete"
                      data-testid="delete-signature"
                      type="button"
                      color="primary"
                      onClick={handleDelete}
                      style={{ marginRight: "10px" }}
                    >
                      Delete
                    </Button>
                  )}
                  <Button
                    aria-label="Save"
                    data-testid="save-signature"
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={!isValid || isSubmitting}
                  >
                    <Loader isLoading={isSubmitting}>Save</Loader>
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </SettingsPageContent>
    </SettingsPageWrapper>
  );
}

Signature.propTypes = {
  createSignatureRequest: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  deleteSignatureRequest: PropTypes.func.isRequired,
  toggleSidebar: PropTypes.func.isRequired,
  updateRecords: PropTypes.func.isRequired,
  updateSignatureRequest: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    currentUser: selectCurrentUser(state, props),
  };
};

const withConnect = connect(mapStateToProps, {
  updateRecords: updateRecordsActionCreator,
});

export default compose(
  withRecord({
    actions: ["create", "update", "delete"],
    container: "containers/Signature",
    type: "signature",
  }),
  withConnect,
)(Signature);
