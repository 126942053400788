import {
  Box,
  Chip,
  Grid,
  SvgIcon,
  Typography,
  Theme,
  useMediaQuery,
  Card,
} from "@mui/material";
import CampaignMetric from "./CampaignMetric";
import { CampaignVariantCardProps } from "./types";
import CampaignMessage from "./CampaignMessage";
import ICONS from "constants/icons";
import { useCurrentAccount } from "hooks";
import { campaignVariantMap } from "features/Campaigns/constants";

const METRICS = ["recipients", "responseRate"] as const;

function CampaignVariantCard({
  campaign,
  campaignTemplate,
  topPerformer,
  messageNumber,
}: CampaignVariantCardProps) {
  const {
    featureFlags: { campaignsResponsiveness = false },
  } = useCurrentAccount();

  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const {
    attachments: { members: attachments = [] } = {},
    deliveryStatistics,
    registeredLinks: { members: registeredLinks = [] } = {},
  } = campaignTemplate;

  const { user } = campaign;
  const { replied, total } = deliveryStatistics;

  const totalClickCount = registeredLinks.reduce((sum, { clickCount }) => {
    return sum + clickCount;
  }, 0);

  const campaignAnalytics = {
    recipients: total || 0,
    responseRate: replied / total || 0,
    totalClicks: totalClickCount || 0,
    clickThroughRate: totalClickCount / total || 0,
  };

  const metrics = [
    ...METRICS,
    ...(registeredLinks.length > 0
      ? (["totalClicks", "clickThroughRate"] as const)
      : []),
  ] as const;

  /*
    When flag is removed, delete the second half of the below ternary. The Card wrapper
    is no longer needed, and some of the styling is slightly different.
  */
  return campaignsResponsiveness ? (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
      flexDirection={mobileScreen ? "column" : "row"}
    >
      <Box display="flex" justifyContent="flex-start" padding="1rem 0.5rem">
        <CampaignMessage
          campaign={{ ...campaign, ...campaignTemplate }}
          campaignsProUser={typeof user === "string" ? undefined : user}
          attachment={attachments?.length ? attachments[0] : undefined}
        />
      </Box>
      <Box
        sx={(theme) => {
          return {
            backgroundColor: theme.palette.background.paper,
            width: "40%",
            maxWidth: "40rem",
          };
        }}
        p={2}
      >
        <Grid container>
          {metrics.map((metric) => {
            return (
              <Grid item xs={12} md={6} key={metric} maxWidth="600px">
                <CampaignMetric
                  campaignAnalytics={campaignAnalytics}
                  metric={metric}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  ) : (
    <Card sx={{ width: "100%" }}>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-between"
        flexDirection={mobileScreen ? "column" : "row"}
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          flexDirection="column"
          padding="1rem 0.5rem"
        >
          <Typography
            style={{ fontSize: 16, fontWeight: 500, marginBottom: 8 }}
          >
            Message {campaignVariantMap[messageNumber]}
          </Typography>
          <CampaignMessage
            campaign={{ ...campaign, ...campaignTemplate }}
            campaignsProUser={typeof user === "string" ? undefined : user}
            attachment={attachments?.length ? attachments[0] : undefined}
          />
        </Box>

        <Box
          sx={(theme) => {
            return {
              backgroundColor: theme.palette.grey[300],
              width: "40%",
              maxWidth: "40rem",
            };
          }}
          p={2}
        >
          {topPerformer && (
            // Todo: Icon can be updated after MUI update
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Chip
                sx={{ fontSize: "18px", fontWeight: 700, padding: "16px" }}
                icon={
                  <SvgIcon viewBox="0 0 24 24">
                    <path d={ICONS.TROPHY} />
                  </SvgIcon>
                }
                label="Top Performer"
                color="primary"
              />
            </Box>
          )}
          <Grid container>
            {metrics.map((metric) => {
              return (
                <Grid item xs={12} md={6} key={metric} maxWidth="600px">
                  <CampaignMetric
                    campaignAnalytics={campaignAnalytics}
                    metric={metric}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Card>
  );
}

export default CampaignVariantCard;
