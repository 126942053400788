/* eslint-disable react/no-unstable-nested-components */
import PropTypes from "prop-types";
import styled from "styled-components";
import get from "lodash/get";
import { Link } from "react-router-dom";
import { IconButton, Tooltip, useMediaQuery, Box } from "@mui/material";
import { useSnackbar } from "notistack";
import ContactDetails from "./ContactDetails";
import {
  ArchiveFilledIcon,
  ArrowBackIcon,
  RecentActorsIcon,
  AccountBoxIcon,
  SwapHorizIcon,
} from "icons";
import ConversationSidebar from "features/Inbox/containers/ConversationSidebar";
import ConversationUpdateOption from "components/ConversationUpdateOption";
import ScheduledMessages from "features/Inbox/containers/ScheduledMessages";
import conversationDisplayProperties from "utils/conversationDisplayProperties";
import NewMessage from "containers/NewMessage";
import ConversationAssignmentsOption from "features/ActiveConversation/containers/ConversationAssignmentsOption";
import PageLoader from "components/PageLoader";
import ParticipantsOption from "components/ParticipantsOption";
import SubscriptionStatusPill from "components/SubscriptionStatusPill";
import Timeline from "features/ActiveConversation/containers/Timeline";
import SnackbarCloseButton from "containers/SnackbarCloseButton";
import { Snackbar } from "components/Snackbar";

const WARNING_HEIGHT = "40px";

const Wrapper = styled.div`
  display: ${(props) => {
    return props.isMobile && props.conversationSidebarActive ? "none" : "flex";
  }};
  flex-flow: column nowrap;
  flex: 1 0 0%;
  height: 100%;
  width: 100%;
  max-width: none;
  min-width: 280px;
`;

const TimelineLoader = styled.div`
  flex: 1 1 auto;
  position: relative;
`;

const Warning = styled.div`
  background: ${(props) => {
    return props.theme.colors.primary.main;
  }};
  color: ${(props) => {
    return props.theme.colors.background.paper;
  }};
  font-size: 14px;
  flex: 0 0 ${WARNING_HEIGHT};
  overflow: hidden;
  padding: 10px 10px 10px 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    fill: ${(props) => {
      return props.theme.colors.background.default;
    }};
  }

  @media (min-width: 768px) {
    width: 100%;
  }
`;

export const Header = styled.header`
  align-items: center;
  border-bottom: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  display: flex;
  flex: 0 0
    ${(props) => {
      return props.theme.layout.topHeaderHeight;
    }};
  height: 1rem;
  justify-content: space-between;
  padding-left: ${(props) => {
    return props.isSinglePanel ? "10px" : "20px";
  }};
`;

export const MainSection = styled.main`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  min-height: 0;
  justify-content: flex-end;
  position: relative;
`;

export const ContactInfo = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  flex: 0 1 auto;
  min-width: 0;

  > button,
  > a {
    font-size: 1rem;
    margin-right: 10px;
  }

  & > section {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-right: 20px;
    min-width: 0;
  }
`;

const ConversationOptionsSection = styled.div`
  display: flex;
  flex-flow: row;
  margin-right: 15px;
`;

const IconsWrapper = styled(Box)((props) => {
  return {
    display: props.mobileScreen ? "none" : "flex",
    height: "60px",
    paddingTop: "10px",
    paddingBottom: "10px",
  };
});

const AssignContactIconButton = styled(IconButton)``;
const ViewAssignedContactIconButton = styled(IconButton)``;
export default function Root({
  activeConversationFilter,
  addNotification,
  appSettings,
  backClickHandler,
  closeConversationRequest,
  containerQueryParams,
  conversation,
  currentAccount,
  currentContact,
  currentUser,
  exportConversationRequest,
  assignConversationRequest,
  isConversationSidebarActive,
  readConversationRequest,
  reopenConversationRequest,
  setContactModal,
  showConversationSidebar = true,
  showContactDetailsSidebar,
  starConversationRequest,
  toggleConversationSidebar,
  typingIndicatorsContainer,
  unassignConversationRequest,
  unreadConversationRequest,
  unstarConversationRequest,
}) {
  const smallScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("md");
  });

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const mdAndBelow = useMediaQuery((theme) => {
    return theme.breakpoints.down("lg");
  });

  const hideAvatar = (showContactDetailsSidebar && mdAndBelow) || mobileScreen;

  const hideCloseConversations = showContactDetailsSidebar && mdAndBelow;

  const collapseIconMenu =
    mobileScreen || (mdAndBelow && showContactDetailsSidebar);

  const { enqueueSnackbar } = useSnackbar();

  const handleAssignContact = () => {
    assignConversationRequest(
      conversation.assignContact,
      {
        assignedContact: currentContact,
      },
      {
        successCallback: () => {
          addNotification({
            message: `Successfully assigned contact to this conversation`,
            options: {
              action: (key) => {
                return <SnackbarCloseButton snackbarKey={key} />;
              },
            },
          });
        },
      },
    );
  };

  const handleUpdateCurrentContact = () => {
    setContactModal({
      contact: conversation.assignedContact,
    });
  };

  const handleCloseConversation = () => {
    return closeConversationRequest(`${conversation.id}/close`, null, {
      successCallback: () => {
        enqueueSnackbar(`Conversation closed.`, {
          content: (key, message) => {
            return (
              <Snackbar
                id={key}
                message={message}
                variant="info"
                width="auto"
              />
            );
          },
        });
      },
    });
  };

  const renderBackButton = () => {
    if (backClickHandler) {
      return (
        <IconButton onClick={backClickHandler} size="large">
          <ArrowBackIcon fontSize="small" />
        </IconButton>
      );
    }
    return (
      <IconButton
        component={(props) => {
          return (
            <Link
              to={document.location.pathname.split("/").slice(0, -1).join("/")}
              {...props}
            />
          );
        }}
        size="large"
      >
        <ArrowBackIcon fontSize="small" />
      </IconButton>
    );
  };

  const {
    blocked,
    participants: { members: participants = [] } = {},
    phoneNumber,
    timeline,
    unsubscribed,
  } = conversation;

  const {
    assignedContact,
    name: conversationName,
    number,
  } = conversationDisplayProperties(conversation);

  const timelineMembers = get(timeline, "members", []);

  const hasScheduledMessages = timelineMembers.some((member) => {
    return (
      member["@type"] === "Message" && member.deliveryState === "scheduled"
    );
  });

  const showWarning =
    currentContact &&
    currentContact !== get(conversation, ["assignedContact", "id"], "");

  const conversationAccountId = get(conversation, ["account", "id"]);

  const readOnly =
    Boolean(conversationAccountId) &&
    conversationAccountId !== currentAccount.id;

  const latestOutboundMessage = timelineMembers.find((member) => {
    return !!member.sender && !member.sender.automatedActor;
  });

  const conversationTypingIndicators =
    typingIndicatorsContainer.typingIndicators[conversation.id] || [];

  return (
    <Wrapper
      isMobile={mobileScreen}
      conversationSidebarActive={isConversationSidebarActive}
    >
      {showWarning && (
        <Warning>
          <span>
            The contact you are viewing is not assigned to this conversation
          </span>
          <span>
            <Tooltip
              title="Assign current contact to this conversation"
              onClick={handleAssignContact}
            >
              <AssignContactIconButton>
                <SwapHorizIcon fontSize="medium" />
              </AssignContactIconButton>
            </Tooltip>
            <Tooltip
              title="Take me to the assigned contact"
              onClick={handleUpdateCurrentContact}
            >
              <ViewAssignedContactIconButton>
                <AccountBoxIcon fontSize="medium" />
              </ViewAssignedContactIconButton>
            </Tooltip>
          </span>
        </Warning>
      )}
      <Header isSinglePanel={smallScreen} mobileScreen={mobileScreen}>
        {phoneNumber && (
          <ContactInfo data-testid="contact-info">
            {smallScreen ? renderBackButton() : null}

            {blocked || unsubscribed ? (
              <section>
                <SubscriptionStatusPill
                  isOptedOut={Boolean(unsubscribed)}
                  isBlocked={blocked}
                />
              </section>
            ) : null}

            <ContactDetails
              contact={assignedContact}
              conversationName={conversationName}
              number={number}
            />
          </ContactInfo>
        )}
        {!readOnly && (
          <ConversationOptionsSection
            isSinglePanel={smallScreen}
            data-product-tour="ConversationOptionsSection"
            mobileScreen={mobileScreen}
          >
            {currentAccount.multiUser && hideAvatar ? null : (
              <ConversationAssignmentsOption
                currentAccount={currentAccount}
                currentUser={currentUser}
                conversation={conversation}
              />
            )}
            <IconsWrapper>
              {!mobileScreen ? (
                <ParticipantsOption participants={participants} />
              ) : null}
              {!mobileScreen && conversation.currentState === "open" ? (
                hideCloseConversations ? null : (
                  <Tooltip title="Close conversation">
                    <IconButton
                      onClick={handleCloseConversation}
                      data-testid="close-conversation"
                    >
                      <ArchiveFilledIcon />
                    </IconButton>
                  </Tooltip>
                )
              ) : null}
              <ConversationUpdateOption
                addNotification={addNotification}
                closeConversationRequest={closeConversationRequest}
                conversation={conversation}
                currentAccount={currentAccount}
                currentUser={currentUser}
                exportConversationRequest={exportConversationRequest}
                assignConversationRequest={assignConversationRequest}
                readConversationRequest={readConversationRequest}
                reopenConversationRequest={reopenConversationRequest}
                starConversationRequest={starConversationRequest}
                unassignConversationRequest={unassignConversationRequest}
                unreadConversationRequest={unreadConversationRequest}
                unstarConversationRequest={unstarConversationRequest}
                collapseIconMenu={collapseIconMenu}
              />
              {showConversationSidebar && (
                <Tooltip title="Contact info" disableFocusListener>
                  <IconButton
                    onClick={toggleConversationSidebar}
                    data-product-tour="ConversationSidebarButton"
                    color={
                      isConversationSidebarActive ? "secondary" : "default"
                    }
                  >
                    <RecentActorsIcon data-testid="contact-icon" />
                  </IconButton>
                </Tooltip>
              )}
            </IconsWrapper>
          </ConversationOptionsSection>
        )}
      </Header>
      <MainSection withWarning={showWarning}>
        {typeof timeline === "object" ? (
          <Timeline
            appSettings={appSettings}
            activeConversation={conversation}
            containerQueryParams={containerQueryParams}
            typingIndicators={conversationTypingIndicators}
            currentUser={currentUser}
            readConversationRequest={readConversationRequest}
          />
        ) : (
          <TimelineLoader>
            <PageLoader />
          </TimelineLoader>
        )}
        {typeof timeline === "object" && hasScheduledMessages && (
          <ScheduledMessages
            activeConversationFilterSlug={get(activeConversationFilter, [
              "slug",
            ])}
            appSettings={appSettings}
            timeline={timeline}
            containerQueryParams={containerQueryParams}
            currentUser={currentUser}
          />
        )}
        {phoneNumber && !readOnly && (
          <NewMessage
            activateSignature={
              get(latestOutboundMessage, ["sender", "id"]) !== currentUser.id
            }
            contact={assignedContact}
            conversationId={conversation.id}
            conversation={conversation}
            data-testid="new-message"
            placeholder={`Reply to ${
              assignedContact && assignedContact.name
                ? assignedContact.name.split(" ")[0]
                : number
            }`}
            recipients={[number]}
            unsubscribed={unsubscribed}
            showConversationSidebar={showConversationSidebar}
            showContactDetailsSidebar={showContactDetailsSidebar}
          />
        )}
      </MainSection>
      {showConversationSidebar && isConversationSidebarActive && (
        <ConversationSidebar conversation={conversation} />
      )}
    </Wrapper>
  );
}

Root.propTypes = {
  activeConversationFilter: PropTypes.object.isRequired, // withConnect
  addNotification: PropTypes.func.isRequired, // withConnect
  appSettings: PropTypes.object.isRequired, // withConnect
  assignConversationRequest: PropTypes.func.isRequired, // withRecord
  backClickHandler: PropTypes.func, // parent
  closeConversationRequest: PropTypes.func.isRequired, // withRecord
  containerQueryParams: PropTypes.object.isRequired, // ContainerQuery
  conversation: PropTypes.object.isRequired, // withRecord
  currentAccount: PropTypes.object.isRequired, // withConnect
  currentContact: PropTypes.string, // parent
  currentUser: PropTypes.object.isRequired, // withConnect
  exportConversationRequest: PropTypes.func.isRequired, // withRecord
  isConversationSidebarActive: PropTypes.bool, // parent
  readConversationRequest: PropTypes.func.isRequired, // withRecord
  reopenConversationRequest: PropTypes.func.isRequired, // withRecord
  showConversationSidebar: PropTypes.bool, // parent
  showContactDetailsSidebar: PropTypes.bool, // parent
  starConversationRequest: PropTypes.func.isRequired, // withRecord
  setContactModal: PropTypes.func.isRequired, // parent
  toggleConversationSidebar: PropTypes.func, // parent
  typingIndicatorsContainer: PropTypes.object.isRequired, // withConnect
  unassignConversationRequest: PropTypes.func.isRequired, // withRecord
  unreadConversationRequest: PropTypes.func.isRequired, // withRecord
  unstarConversationRequest: PropTypes.func.isRequired, // withRecord
};
