import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useState, useEffect } from "react";
import { isSameDay, roundToNearestMinutes } from "date-fns";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { campaignScheduleRadioOptions } from "../../constants";
import { CampaignScheduleRadioSelection } from "../../types";
import { OneTimeCampaignSchedulerProps } from "./types";
import { useTimeZones } from "hooks";

function OneTimeCampaignScheduler({
  groupName,
  isEditing,
  scheduledAt,
  scheduleError,
  setScheduledAt,
  setScheduleError,
}: OneTimeCampaignSchedulerProps) {
  const { now, later } = campaignScheduleRadioOptions;

  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const { accountTimeZone, currentTime } = useTimeZones();

  // If editing, saves the originally scheduled date.
  const [originalDate, setOriginalDate] = useState<Date>(scheduledAt);

  const [radioSelection, setRadioSelection] =
    useState<CampaignScheduleRadioSelection>(isEditing ? later : now);

  const handleScheduleChange = (value: Date | null) => {
    setScheduledAt(value ?? currentTime);
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioSelection(event.target.value as CampaignScheduleRadioSelection);

    // Round up to the nearest 5 minutes, e.g. 12:01 -> 12:05
    const currentTimeRoundedUp = roundToNearestMinutes(currentTime, {
      nearestTo: 5,
      roundingMethod: "ceil",
    });

    return event.target.value === later
      ? setScheduledAt(isEditing ? originalDate : currentTimeRoundedUp)
      : setScheduledAt(isEditing ? currentTimeRoundedUp : currentTime);
  };

  useEffect(() => {
    setOriginalDate(scheduledAt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4">Schedule message</Typography>
        <Typography variant="body2">
          Sending to group: <b>{groupName}</b>
        </Typography>
        <Box margin="1.5rem">
          <FormControl>
            <RadioGroup
              aria-labelledby="campaign-schedule-label"
              name="campaign-schedule-radio-group"
              value={radioSelection}
              onChange={handleRadioChange}
            >
              <FormControlLabel
                value={now}
                control={<Radio color="secondary" />}
                label="Send now"
              />
              <FormControlLabel
                value={later}
                control={<Radio color="secondary" />}
                label="Schedule for later"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      {radioSelection === later ? (
        <Box
          display="flex"
          flexDirection={mobileScreen ? "column" : "row"}
          alignItems="center"
          justifyContent="center"
          marginBottom="2rem"
          width="100%"
          gap="1rem"
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              name="Campaign DatePicker"
              disablePast
              format="MMMM dd, yyyy"
              label="Date"
              value={scheduledAt}
              onChange={handleScheduleChange}
              onError={(error) => {
                setScheduleError(Boolean(error));
              }}
              slotProps={{
                textField: {
                  helperText: scheduleError ? "Select a future date" : "",
                  error: scheduleError,
                },
              }}
              timezone={accountTimeZone}
            />
            <TimePicker
              name="Campaign TimePicker"
              disablePast={isSameDay(scheduledAt, new Date())}
              label="Time"
              value={scheduledAt}
              onChange={handleScheduleChange}
              onError={(error) => {
                setScheduleError(Boolean(error));
              }}
              slotProps={{
                textField: {
                  helperText: scheduleError ? "Select a future time" : "",
                  error: scheduleError,
                },
              }}
              timezone={accountTimeZone}
            />
          </LocalizationProvider>
        </Box>
      ) : null}
    </>
  );
}

export { OneTimeCampaignScheduler };
