import { Box } from "@mui/material";

export function StepSection({
  campaignsResponsiveness,
  children,
  sx,
}: {
  campaignsResponsiveness: boolean;
  children: React.ReactNode;
  sx?: object;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        flex: "1 1 auto",
        flexDirection: "column",
        minHeight: "0",
        p: campaignsResponsiveness ? "0rem" : "1.5rem",
        width: "100%",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
}
