import { Box, Typography, Button } from "@mui/material";
import { useState } from "react";
import { SelectRecipientsProps } from "../types";
import { StepSection } from "./StepSection";
import { ClickToUploadSelectGroup } from "./ClickToUploadSelectGroup";
import { ClickToUploadSelectGroupResponsive } from "./ClickToUploadSelectGroupResponsive";

export function SelectRecipients({
  campaignType,
  campaignsResponsiveness,
  contactImport,
  mode,
  setSelectGroup,
}: SelectRecipientsProps) {
  // ==== FIELDS ==== //
  const responsiveCopy = {
    oneTime:
      "Upload a spreadsheet, select a group, or select contacts that you would like to receive your campaign. At a minimum the spreadsheet must contain a contact name and phone number.",
  };
  const oneTimeCampaign = campaignType === "single";
  const selectRecipientsText = oneTimeCampaign
    ? campaignsResponsiveness
      ? responsiveCopy.oneTime
      : `Please upload a spreadsheet, select a group, or select contacts
    that you would like to receive your campaign. At a minimum the
    spreadsheet must contain a contact name and phone number.`
    : "Please select the group that you would like to receive your campaign.";

  // ==== HOOKS ==== //
  const [fileUploaded, setFileUploaded] = useState(false);

  // ==== RENDER ==== //
  return (
    <StepSection
      campaignsResponsiveness={campaignsResponsiveness}
      sx={{
        marginTop: { xs: "32px", sm: "0" },
      }}
    >
      <Typography
        align="center"
        style={{ fontSize: campaignsResponsiveness ? "1.5rem" : "2.5rem" }}
        variant={campaignsResponsiveness ? "h4" : "h2"}
      >
        {campaignsResponsiveness ? "Select recipients" : "Select Recipients"}
      </Typography>
      <Box
        m={campaignsResponsiveness ? "0" : "1rem 0 2rem"}
        sx={{ padding: { xs: "2rem 1rem", sm: "0rem 1rem" } }}
      >
        <Typography
          align="center"
          component="p"
          color="textSecondary"
          style={{ fontSize: "1rem" }}
        >
          {selectRecipientsText}
        </Typography>
      </Box>
      {campaignsResponsiveness ? (
        <ClickToUploadSelectGroupResponsive
          contactImport={contactImport}
          oneTimeCampaign={oneTimeCampaign}
          mode={mode}
          setFileUploaded={setFileUploaded}
          setSelectGroup={setSelectGroup}
        />
      ) : (
        <ClickToUploadSelectGroup
          contactImport={contactImport}
          oneTimeCampaign={oneTimeCampaign}
          setFileUploaded={setFileUploaded}
          setSelectGroup={setSelectGroup}
        />
      )}
    </StepSection>
  );
}
