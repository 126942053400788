import { Box, Typography } from "@mui/material";
import { CampaignVariantsPreviewProps } from "./types";
import CampaignMessage from "features/Campaigns/containers/Campaign/components/CampaignMessage";
import { campaignVariantMap } from "features/Campaigns/constants";

function CampaignVariantsPreview({
  messageTemplates,
  currentUser,
}: CampaignVariantsPreviewProps) {
  const signatureContent = currentUser?.signature?.content ?? "";

  const multipleVariants = messageTemplates.length > 1;

  return (
    <Box>
      {messageTemplates.map(
        ({ attachments, body, signatureActive, id }, index) => {
          const campaignVariant = {
            messageBody: signatureActive
              ? `${body}\n\n${signatureContent}`
              : body,
            user: currentUser,
          };
          return (
            <Box key={id}>
              {multipleVariants ? (
                <Typography variant="h5" marginBottom="1rem">
                  Message {campaignVariantMap[index + 1]}
                </Typography>
              ) : null}
              <Box>
                {body && (
                  <CampaignMessage
                    attachment={attachments[0]}
                    campaign={campaignVariant}
                  />
                )}
              </Box>
            </Box>
          );
        },
      )}
    </Box>
  );
}

export default CampaignVariantsPreview;
