// TODO: (JOHN BROWN) DELETE ME AFTER CAMPAIGNS RESPONSIVE GOES LIVE

import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import NewContactImport from "features/ContactImports/containers/NewContactImport";

export function ClickToUploadSelectGroup({
  contactImport,
  oneTimeCampaign,
  setSelectGroup,
}: any) {
  // ==== HOOKS ==== //
  const [fileUploaded, setFileUploaded] = useState(false);

  // ==== RENDER ==== //
  return (
    <Box display="flex" alignSelf="center">
      <Box display="flex" maxWidth="1000px" padding="0px">
        <Box display="flex" alignSelf="center">
          <Box display="flex" maxWidth="1000px">
            <Box display="flex" width="100vw">
              {oneTimeCampaign && (
                <Box flexGrow="1" justifyContent="center" maxWidth="1000px">
                  <NewContactImport
                    contactImport={contactImport}
                    fromCampaign
                    setFileUploaded={setFileUploaded}
                  />
                </Box>
              )}
              {!contactImport && !fileUploaded && (
                <Box flexGrow="1" display="flex" justifyContent="center">
                  <Box justifyContent="center" alignSelf="center">
                    <Box m="1rem" textAlign="center">
                      <FontAwesomeIcon
                        icon={faUsers}
                        style={{ fontSize: "4.5rem" }}
                        color="rgba(0, 0, 0, .5)"
                      />
                    </Box>
                    <Button
                      color="primary"
                      onClick={() => {
                        return setSelectGroup(true);
                      }}
                      variant="contained"
                    >
                      Select Group
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
