import { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { utcToZonedTime } from "date-fns-tz";
import { dayToSend, sendFrequency } from "./constants/timeOptions";
import { RecurringCampaignTimeFormProps } from "./types";
import FormikSelect from "components/FormikSelect";
import CampaignCutoffInfo from "components/CampaignCutoffInfo";
import { useTimeZones } from "hooks";

function RecurringCampaignTimeForm({
  currentAccount,
  errors,
  groupName = "",
  handleBlur,
  setFieldValue,
  timeZone,
  touched,
  values,
}: RecurringCampaignTimeFormProps) {
  const { accountTimeZone } = useTimeZones();

  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  const [dayToSendOptions, setDayToSendOptions] = useState<
    (typeof dayToSend)[keyof typeof dayToSend]
  >([]);

  const updateDependentField = (
    parentField: string,
    value: keyof typeof dayToSend,
  ) => {
    return setDayToSendOptions(dayToSend[value]);
  };

  const fields = [
    {
      dependentFieldOptions: ["dayToSend"],
      parentField: true,
      items: sendFrequency,
      label: "Repeat",
      name: "sendFrequency",
      required: true,
      postScript: "on",
    },
    {
      dependentFieldOptions: [],
      items: dayToSendOptions,
      label: "Day",
      name: "dayToSend",
      required: true,
      postScript: "at",
    },
  ];

  useEffect(() => {
    if (!values.sendTime) {
      setFieldValue(
        "sendTime",
        utcToZonedTime(new Date(), timeZone || accountTimeZone),
        true,
      ).catch((error) => {
        return console.error(error);
      });
    }
  }, [values.sendTime, timeZone, setFieldValue, accountTimeZone]);

  return (
    <Box
      display="flex"
      flex="1 1 auto"
      flexDirection="column"
      minHeight="0"
      p="20px"
      width="100%"
    >
      <Typography align="center" variant="h4" fontWeight={700}>
        Schedule message
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        margin="0.25rem 0 1.5rem 0"
      >
        <Typography align="center" component="p" variant="body2">
          Sending to group: <b>{groupName}</b>
        </Typography>
        <CampaignCutoffInfo currentAccount={currentAccount} />
      </Box>
      <Box alignSelf="center" width="100%">
        <Grid container justifyContent="center" spacing={mobileScreen ? 1 : 2}>
          {fields.map(
            ({
              dependentFieldOptions,
              name,
              items,
              label,
              required,
              parentField,
              postScript,
            }) => {
              return (
                <Grid item xs={12} sm={6} md={4} key={name}>
                  <Box
                    display="flex"
                    flexDirection={mobileScreen ? "column" : "row"}
                    gap={mobileScreen ? "0.25rem" : "1rem"}
                  >
                    <Box width="100%">
                      <FormikSelect
                        dependentFieldOptions={dependentFieldOptions}
                        handleChange={
                          parentField ? updateDependentField : false
                        }
                        items={items}
                        label={label}
                        name={name}
                        required={required}
                        setFieldValue={setFieldValue}
                        values={values}
                      />
                    </Box>
                    <Box alignSelf="center" justifySelf="center">
                      <Typography component="p" color="textSecondary">
                        {postScript}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              );
            },
          )}
          <Grid item xs={12} sm={6} md={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                value={values.sendTime || new Date()}
                onChange={(newTimeValue) => {
                  setFieldValue("sendTime", new Date(newTimeValue), true).catch(
                    (error) => {
                      return console.error(error);
                    },
                  );
                }}
                slotProps={{
                  textField: {
                    name: "sendTime",
                    required: true,
                    onBlur: handleBlur,
                    error: touched.sendTime && Boolean(errors.sendTime),
                    helperText: touched.sendTime ? errors?.sendTime : "",
                    fullWidth: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Box my="1rem" maxWidth="200px">
          <Typography variant="h6">Campaign ends</Typography>
          <Box display="flex" flexDirection="column" mt=".5rem">
            <Box>
              <Checkbox
                color="secondary"
                inputProps={{ "aria-label": "Campaign Runs Infinitely" }}
                name="runsIndefinitely"
                onBlur={handleBlur}
                onChange={() => {
                  setFieldValue(
                    "runsIndefinitely",
                    !values.runsIndefinitely,
                    true,
                  ).catch((error) => {
                    return console.error(error);
                  });
                }}
                sx={{ marginLeft: "-10px" }}
                checked={values.runsIndefinitely ?? false}
              />
              Never
            </Box>
            {values.runsIndefinitely ? null : (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  disabled={values.runsIndefinitely}
                  disablePast
                  label="Date"
                  format="MMMM dd, yyyy"
                  value={
                    (!values.runsIndefinitely && values.campaignEndDate) ?? null
                  }
                  onChange={(newEndDate) => {
                    setFieldValue("campaignEndDate", newEndDate, true).catch(
                      (error) => {
                        return console.error(error);
                      },
                    );
                  }}
                  slotProps={{
                    textField: {
                      name: "campaignEndDate",
                      required: !values.runsIndefinitely,
                      onBlur: handleBlur,
                      error:
                        touched.campaignEndDate && !!errors.campaignEndDate,
                      helperText:
                        touched.campaignEndDate && errors?.campaignEndDate,
                      placeholder: "",
                    },
                  }}
                />
              </LocalizationProvider>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default RecurringCampaignTimeForm;
