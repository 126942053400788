import { useEffect, useState } from "react";
import get from "lodash/get";
import queryString from "query-string";
import PropTypes from "prop-types";
import {
  IconButton,
  Snackbar,
  SnackbarContent,
  useMediaQuery,
} from "@mui/material";
import BatchActionToolbar from "components/BatchActionToolbar";
import ConfirmationModal from "components/ConfirmationModal/ConfirmationModal";
import getNestedId from "utils/getNestedId";
import { SequenceEnroller } from "features/Sequences/components/SequenceEnroller";
import { extractCampaignContactFilterSlug } from "features/Sequences/components/SequenceEnroller/utils";
import {
  CampaignIcon,
  CheckCircleIcon,
  CloseIcon,
  SequencesIcon,
  MobileOffIcon,
  RemoveCircleIcon,
  SendIcon,
} from "icons";
import {
  ConfirmEnrollmentDrawer,
  SelectSequenceDrawer,
} from "features/Sequences/components/Drawers";
import { useSequenceEnroller } from "features/Sequences/hooks/useSequenceEnroller";
import { fetchV3Accounts } from "features/Accounts/api/fetchV3";

const BATCH_MESSAGE_LIMIT = 10;

const getActionPastParticiple = (action) => {
  if (action.endsWith("e")) return `${action}d`;
  return `${action}ed`;
};

const getRequestUrl = ({ action, collection, selectAll }) => {
  const actionRequestUrl = collection[`${action}Contacts`];
  const [base, query] = actionRequestUrl.split("?");
  const queryParams = queryString.stringify({
    ...queryString.parse(query),
    selectAll: selectAll ? "true" : undefined,
  });
  return `${base}${queryParams ? `?${queryParams}` : ""}`;
};

const formatRecords = (records) => {
  return records
    .filter((record) => {
      return Boolean(record.contactPhone);
    })
    .map(({ contactPhone }) => {
      return {
        name: contactPhone.contact.name,
        contactPhoneId: contactPhone.id,
        deliverabilityStatus: contactPhone.deliverabilityStatus,
        formattedPhoneNumber: contactPhone.formattedPhoneNumber,
      };
    });
};

export function CampaignRecipientsBatchActions(props) {
  const {
    allSelected,
    campaignRecipientCollection,
    currentAccount,
    handleSetCompose,
    history,
    left,
    selected,
    selectedRecords,
    setAllSelected,
    setSelected,
    setSelectedRecords,
  } = props;

  const [confirmationModalAction, setConfirmationModalAction] = useState();
  const [notificationMessage, setNotificationMessage] = useState();
  const [displayedContacts, setDisplayedContacts] = useState([]);
  const [pagination, setPagination] = useState(
    campaignRecipientCollection.view,
  );

  const mobileScreen = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const {
    allSequences,
    confirmEnrollmentDrawerIsOpen,
    isLoadingContacts,
    selectedSequence,
    selectSequenceDrawerIsOpen,
    sequenceSteps,
    backToSelectSequenceDrawer,
    closeConfirmEnrollmentDrawer,
    closeSelectSequenceDrawer,
    confirmEnrollment,
    openSelectSequenceDrawer,
    selectSequence,
  } = useSequenceEnroller(campaignRecipientCollection.id);

  useEffect(() => {
    if (selectedRecords) {
      setDisplayedContacts(formatRecords(selectedRecords));
    }

    if (allSelected) {
      setDisplayedContacts(formatRecords(campaignRecipientCollection.members));
    }
  }, [allSelected, campaignRecipientCollection.members, selectedRecords]);

  const createClickHandler = (action, message = null) => {
    return () => {
      const requestUrl = getRequestUrl({
        action,
        collection: campaignRecipientCollection,
        selectAll: allSelected,
      });
      const totalCount = allSelected
        ? campaignRecipientCollection.totalItems
        : selected.length;
      props[`${action}CampaignRecipientCollectionRequest`](requestUrl, {
        recipients: selected,
      });
      const baseMessage = `contacts ${getActionPastParticiple(action)}`;
      setNotificationMessage(
        `${totalCount.toLocaleString()} ${message || baseMessage}`,
      );
    };
  };

  const handleNotificationClose = () => {
    setNotificationMessage(undefined);
  };

  const handleSendCampaignClick = () => {
    const contactFilterId = getNestedId(
      campaignRecipientCollection,
      "sendCampaign",
    );
    const state = allSelected
      ? { contactFilterId, initialActiveStep: 1 }
      : { selectedContacts: selected };
    history.push({
      pathname: `${global.document.location.pathname
        .split("/")
        .slice(0, 2)
        .join("/")}/campaigns/new`,
      state,
    });
  };

  const handleModalCancel = () => {
    setConfirmationModalAction(undefined);
  };

  const handleModalConfirm = () => {
    const message = "opt-in requests sent";
    createClickHandler("subscribe", message)();
    setConfirmationModalAction(undefined);
  };

  const getBatchActions = () => {
    const batchActions = [
      {
        title: "Send message",
        icon: <SendIcon />,
        clickHandler: handleSetCompose,
        showExpanded: true,
      },
      {
        title: "Send campaign",
        icon: <CampaignIcon />,
        clickHandler: handleSendCampaignClick,
        showExpanded: true,
      },
      {
        title: "Enroll in sequence",
        icon: <SequencesIcon />,
        clickHandler: openSelectSequenceDrawer,
        button: mobileScreen ? null : (
          <SequenceEnroller
            key="enroller"
            allSelected={allSelected}
            buttonType="icon"
            currentAccount={currentAccount}
            contactCollectionId={campaignRecipientCollection.id}
            contactFilterSlug={extractCampaignContactFilterSlug(
              campaignRecipientCollection.id,
            )}
            selected={selected}
            formattedSelectedRecords={formatRecords(selectedRecords)}
            setSelected={() => {
              setAllSelected(false);
            }}
            setSelectedRecords={setSelectedRecords}
          />
        ),
        showExpanded: true,
      },
      {
        title: "Opt out number",
        icon: <MobileOffIcon />,
        clickHandler: createClickHandler("unsubscribe"),
      },
      {
        title: "Block number",
        icon: <RemoveCircleIcon />,
        clickHandler: createClickHandler("block"),
      },
      {
        title: "Unblock number",
        icon: <CheckCircleIcon />,
        clickHandler: createClickHandler("unblock"),
      },
    ];

    return batchActions;
  };

  const dailyCampaignRecipients = get(currentAccount, [
    "settings",
    "dailyCampaignRecipients",
    "value",
  ]);
  const selectAllCount = campaignRecipientCollection.totalItems;
  const selectedCount = selected.length;
  const totalSelected = allSelected ? selectAllCount : selectedCount;
  const actions = getBatchActions()
    .filter(({ title }) => {
      return !(title === "Send Message" && totalSelected > BATCH_MESSAGE_LIMIT);
    })
    .filter(({ title }) => {
      return !(
        title === "Send Campaign" && totalSelected > dailyCampaignRecipients
      );
    });

  const selectedContactPhoneIds = selectedRecords.map(
    ({ contactPhone: { id } }) => {
      return id.replace("/contact_phones/", "");
    },
  );

  const fetchNextPage = async () => {
    if (!pagination?.next) {
      return null;
    }
    const response = await fetchV3Accounts(
      currentAccount.slug,
      pagination.next,
    );
    const body = await response.json();
    const newFormattedContacts = formatRecords(body.members);
    setPagination(body.view);
    return setDisplayedContacts([
      ...displayedContacts,
      ...newFormattedContacts,
    ]);
  };

  const campaignSlug = `campaign:${campaignRecipientCollection.members[0].campaign.replace(
    "/campaigns/",
    "",
  )}`;

  return (
    <>
      <BatchActionToolbar
        actions={actions}
        allSelected={allSelected}
        contactCollection={campaignRecipientCollection}
        position={{ top: "0px", left: left || "64px" }}
        selectedCount={selectedCount}
        selectAllCount={selectAllCount}
        setAllSelected={setAllSelected}
        setSelectedContacts={setSelected}
        setSelectedRecords={setSelectedRecords}
        showSelectAll={selectedCount > 0}
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={Boolean(notificationMessage)}
        autoHideDuration={5000}
        onClose={handleNotificationClose}
      >
        <SnackbarContent
          message={<span>{notificationMessage}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={handleNotificationClose}
              size="large"
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
      <ConfirmationModal
        cancellationHandler={handleModalCancel}
        confirmationHandler={handleModalConfirm}
        isOpen={Boolean(confirmationModalAction)}
        message="This will send a message to all the selected recipients that were manually unsubscribed,
          requesting them to opt back in."
        title="Send opt-in request?"
      />
      <SelectSequenceDrawer
        isOpen={selectSequenceDrawerIsOpen}
        sequences={allSequences}
        handleSelectSequence={selectSequence}
        handleClose={closeSelectSequenceDrawer}
      />
      {selectedSequence && (
        <ConfirmEnrollmentDrawer
          allContactsCount={campaignRecipientCollection.totalItems}
          allSelected={allSelected}
          contactCollectionId={campaignRecipientCollection.id}
          contactFilterSlug={campaignSlug}
          currentAccount={currentAccount}
          displayedContacts={displayedContacts}
          fetchNextPage={fetchNextPage}
          isEmpty={campaignRecipientCollection.totalItems === 0}
          isLoading={isLoadingContacts}
          isOpen={confirmEnrollmentDrawerIsOpen}
          selectedContactPhoneIds={selectedContactPhoneIds}
          sequence={selectedSequence}
          sequenceStepOne={sequenceSteps[0]}
          handleBack={backToSelectSequenceDrawer}
          handleConfirm={confirmEnrollment}
          handleClose={closeConfirmEnrollmentDrawer}
        />
      )}
    </>
  );
}

CampaignRecipientsBatchActions.propTypes = {
  allSelected: PropTypes.bool.isRequired,
  campaignRecipientCollection: PropTypes.object.isRequired,
  currentAccount: PropTypes.object.isRequired,
  handleSetCompose: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  left: PropTypes.string,
  selected: PropTypes.array.isRequired,
  selectedRecords: PropTypes.array,
  setAllSelected: PropTypes.func.isRequired,
  setSelected: PropTypes.func,
  setSelectedRecords: PropTypes.func,
};
