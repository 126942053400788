/* eslint-disable unicorn/filename-case */
import { SyntheticEvent, useState } from "react";
import capitalize from "lodash/capitalize";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Chip,
  Divider,
  Grid,
  SvgIcon,
  Tab,
  Tabs,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Campaign } from "@tesseract/core";
import { formatInTimeZone } from "date-fns-tz";
import CampaignMetric from "./CampaignMetric";
import CampaignPreview from "./CampaignPreview";
import CampaignVariantCard from "./CampaignVariantCard";
import { getTopPerformerIndex } from "./getTopPerformerIndex/getTopPerformerIndex";
import { CampaignRecipientGrid } from "./CampaignRecipientGrid";
import { ABCCampaignProps, CampaignAnalytics } from "./types";
import CampaignMessage from "./CampaignMessage";
import ICONS from "constants/icons";
import { LinkIcon, ExpandMoreIcon } from "icons";
import createUUID from "utils/uuid";
import ExpansionPanel from "components/ExpansionPanel";
import ExpansionPanelSummary from "components/ExpansionPanel/Summary";
import { useTimeZones } from "hooks";
import { campaignVariantMap } from "features/Campaigns/constants";

const METRICS = ["recipients", "responseRate", "deliveryRate", "failureRate"];

function ABCCampaign({
  campaign,
  currentAccount,
  location,
  match,
  setCompose,
}: ABCCampaignProps) {
  // ==== HOOKS ==== //
  const [currentTab, setCurrentTab] = useState(0);
  const { accountTimeZone } = useTimeZones();
  const mobileScreen = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down("sm");
  });

  // ==== FIELDS ==== //
  const { campaignsResponsiveness = false } = currentAccount.featureFlags;

  const {
    campaignTemplates: { members: templates = [] } = {},
    schedule,
    scheduledAt,
    state = "",
    title,
    type,
    user,
  } = campaign;

  const timestamp = formatInTimeZone(
    new Date(scheduledAt),
    accountTimeZone,
    "EEEE, MMM do 'at' hh:mm a z",
  );

  const replyRates = templates.map(
    ({ deliveryStatistics: { replied = 0, total = 0 } = {} }) => {
      return replied / total || 0;
    },
  );
  const topPerformerIndex = getTopPerformerIndex(replyRates);

  const template =
    templates.length > 1 && currentTab > 0 ? templates[currentTab - 1] : {};

  const currentTemplate: Partial<Campaign.Template> = {
    ...template,
    schedule,
    scheduledAt,
    state,
    type,
    title,
    user,
  };

  const registeredLinks = currentTemplate?.registeredLinks?.members ?? [];

  const metrics = [
    ...METRICS,
    ...(registeredLinks.length > 0 ? ["totalClicks", "clickThroughRate"] : []),
  ];

  const registeredLinkIncluded = registeredLinks.length > 0;

  // ==== METHODS ==== //
  const handleChange = (e: SyntheticEvent, newTabValue: number) => {
    setCurrentTab(newTabValue);
  };

  // ==== RENDER ==== //
  const renderVariantMetrics = (metric: string) => {
    const { deliveryStatistics } = currentTemplate;
    const {
      delivered = 0,
      failed = 0,
      replied = 0,
      total = 0,
      sent = 0,
    } = deliveryStatistics ?? {};

    const totalClickCount = registeredLinks.reduce((sum, { clickCount }) => {
      return sum + clickCount;
    }, 0);

    const campaignAnalytics = {
      recipients: total || 0,
      responseRate: Math.round((replied / sent) * 100) || 0,
      deliveryRate: Math.round((delivered / sent) * 100) || 0,
      failureRate: Math.round((failed / total) * 100) || 0,
      totalClicks: totalClickCount || 0,
      clickThroughRate: Math.round((totalClickCount / delivered) * 100) || 0,
    };

    return (
      <Grid item xs={2} key={metric}>
        <CampaignMetric
          campaignAnalytics={campaignAnalytics}
          metric={metric as keyof CampaignAnalytics}
        />
      </Grid>
    );
  };

  return (
    <>
      <Tabs
        aria-label="Overview and Messages Tabs"
        indicatorColor="primary"
        onChange={handleChange}
        textColor="primary"
        value={currentTab}
        variant="scrollable"
        scrollButtons={mobileScreen}
        allowScrollButtonsMobile
      >
        <Tab label="Overview" aria-label="Overview" />
        {templates.map((_, i) => {
          return (
            <Tab
              label={`Message ${campaignVariantMap[i + 1]}`}
              aria-label={`Message ${campaignVariantMap[i + 1]}`}
              key={createUUID()}
            />
          );
        })}
      </Tabs>
      <Divider />
      <Box overflow="auto">
        {currentTab ? (
          <Box>
            {campaignsResponsiveness ? null : (
              <Box m={2} display="flex" flexDirection="column">
                <Typography variant="body2" color="text.primary">
                  {capitalize(state)}
                </Typography>
                <Typography variant="body2" fontWeight={700}>
                  {timestamp}
                </Typography>
              </Box>
            )}
            <Box
              display="flex"
              justifyContent={mobileScreen ? "center" : "flex-end"}
              padding="0.5rem"
            >
              <Box
                display="flex"
                flexDirection="column"
                alignItems={
                  mobileScreen || !campaignsResponsiveness
                    ? "center"
                    : "flex-end"
                }
                gap="0.5rem"
              >
                {mobileScreen || !campaignsResponsiveness ? (
                  <CampaignPreview
                    campaign={currentTemplate}
                    registeredLinkIncluded={registeredLinkIncluded}
                  />
                ) : (
                  <CampaignMessage campaign={currentTemplate} />
                )}
                {registeredLinkIncluded && !mobileScreen ? (
                  <Chip
                    color="primary"
                    icon={<LinkIcon />}
                    label="Shortened Link"
                    size="small"
                    sx={{ fontWeight: 600 }}
                  />
                ) : null}
              </Box>
            </Box>
            <ExpansionPanel>
              <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Campaign performance</Typography>
              </ExpansionPanelSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing={{ sm: 2 }}
                  columns={{ xs: 2, sm: 6, lg: 12 }}
                  justifyContent="center"
                >
                  {metrics.map((metric) => {
                    return renderVariantMetrics(metric);
                  })}
                </Grid>
              </AccordionDetails>
            </ExpansionPanel>

            <CampaignRecipientGrid
              campaign={currentTemplate}
              currentAccount={currentAccount}
              location={location}
              match={match}
              setCompose={setCompose}
            />
          </Box>
        ) : (
          <Box>
            {campaignsResponsiveness ? (
              <Box m={2} display="flex" flexDirection="column">
                <Typography variant="body2" color="text.primary">
                  {capitalize(state)}
                </Typography>
                <Typography variant="body2" fontWeight={700}>
                  {timestamp}
                </Typography>
              </Box>
            ) : null}
            <Box
              display="flex"
              flexDirection="column"
              width="100%"
              gap="1rem"
              padding="1rem"
            >
              {templates.map((campaignTemplate, index) => {
                const topPerformer = topPerformerIndex === index;
                return campaignsResponsiveness ? (
                  <Accordion key={campaignTemplate.id}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        margin: 0,
                        padding: "0 0.5rem",
                        flexDirection: "row-reverse",
                        ".MuiAccordionSummary-expandIconWrapper": {
                          marginRight: "0.5rem",
                        },
                        ".MuiAccordionSummary-content": {
                          alignItems: "center",
                          margin: 0,
                          justifyContent: "space-between",
                          "&.Mui-expanded": {
                            margin: 0,
                          },
                        },
                        "&.Mui-expanded": {
                          minHeight: "3rem",
                        },
                      }}
                    >
                      <Typography variant="h6">
                        Message {campaignVariantMap[campaignTemplate.sortOrder]}
                      </Typography>

                      {topPerformer &&
                        (mobileScreen ? (
                          <Avatar
                            alt="Top performer"
                            sx={{
                              bgcolor: "blueGrey.800",
                              width: "2rem",
                              height: "2rem",
                            }}
                          >
                            <SvgIcon viewBox="-4 -4 32 32">
                              <path d={ICONS.TROPHY} />
                            </SvgIcon>
                          </Avatar>
                        ) : (
                          <Chip
                            size="medium"
                            color="primary"
                            variant="filled"
                            icon={
                              <SvgIcon viewBox="-4 -4 32 32">
                                <path d={ICONS.TROPHY} />
                              </SvgIcon>
                            }
                            label="Top performer"
                          />
                        ))}
                    </AccordionSummary>
                    <Divider sx={{ width: "100%" }} />
                    <AccordionDetails>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                      >
                        <CampaignVariantCard
                          campaign={campaign}
                          topPerformer={topPerformer} // TODO: REMOVE AS PROP
                          campaignTemplate={campaignTemplate}
                          messageNumber={index + 1}
                        />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    width={800}
                    key={campaignTemplate.id}
                  >
                    <CampaignVariantCard
                      campaign={campaign}
                      topPerformer={topPerformer}
                      campaignTemplate={campaignTemplate}
                      messageNumber={index + 1}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

export default ABCCampaign;
