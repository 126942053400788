import { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import {
  Box,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AddIcon, EditIcon, ArrowBackIcon } from "icons";

import ModalHeader from "components/ModalHeader";
import SavedReplyList from "features/SavedReplies/components/SavedReplyList";
import SavedReply from "features/SavedReplies/containers/SavedReply";

const ManagerWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  height: ${(props) => {
    return props.fullScreen ? "100%" : "500px";
  }};
  width: ${(props) => {
    return props.fullScreen ? "100%" : "750px";
  }};
  padding-bottom: ${(props) => {
    return props.fullScreen ? "2rem" : "0rem";
  }};
`;

const ManagerMain = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: row nowrap;
  height: 100%;
  min-height: 0;
`;

const ManagerSidebar = styled.div`
  border-right: ${(props) => {
    return props.theme.mixins.border({ color: props.theme.colors.divider });
  }};
  display: flex;
  flex: 1 0 350px;
  flex-flow: column nowrap;
  max-width: 350px;
  position: relative;
  padding-bottom: 1.5rem;
`;

export default function Root({
  activeSavedReply,
  closeHandler,
  currentAccount,
  currentUser,
  handleCloseModal,
  handleEditButtonClick,
  handleSavedReplySelected = () => {},
  hasManageOptions,
  setActiveSavedReply,
  settings = false,
  settingsAccount,
  showManager = false,
}) {
  const mobile = useMediaQuery((theme) => {
    return theme.breakpoints.down("sm");
  });

  const initialSavedReplyCollectionId = `${currentUser.savedReplies}?filter=mine`;

  const [savedReplyCollectionId, setSavedReplyCollectionId] = useState(
    initialSavedReplyCollectionId,
  );
  const [savedReplies, setSavedReplies] = useState();

  const renderList = ({ asSidebar = false } = {}) => {
    const savedReplySelectionHandler = asSidebar
      ? setActiveSavedReply
      : handleSavedReplySelected;
    return (
      <SavedReplyList
        activeSavedReply={activeSavedReply}
        closeHandler={closeHandler}
        currentAccount={currentAccount}
        hasManageOptions={hasManageOptions}
        savedReplies={savedReplies}
        savedReplySelectionHandler={savedReplySelectionHandler}
        savedReplyCollectionId={savedReplyCollectionId}
        setActiveSavedReply={setActiveSavedReply}
        setSavedReplyCollectionId={setSavedReplyCollectionId}
        setSavedReplies={setSavedReplies}
      />
    );
  };

  const renderManager = () => {
    return (
      <ManagerWrapper fullScreen={settings}>
        {!settings && (
          <ModalHeader closeModal={handleCloseModal}>
            <Typography variant="h5" color="text.primary">
              Templates
            </Typography>
            {activeSavedReply && (
              <Tooltip title="Create Template">
                <IconButton
                  onClick={() => {
                    return setActiveSavedReply(undefined);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </ModalHeader>
        )}
        <ManagerMain>
          <ManagerSidebar>{renderList({ asSidebar: true })}</ManagerSidebar>
          <SavedReply
            handleCloseModal={handleCloseModal}
            handleSavedReplySelected={handleSavedReplySelected}
            savedReply={activeSavedReply}
            savedReplies={savedReplies}
            savedReplyCollectionId={savedReplyCollectionId}
            setActiveSavedReply={setActiveSavedReply}
            settings={settings}
            settingsAccount={settingsAccount}
            setSavedReplies={setSavedReplies}
          />
        </ManagerMain>
      </ManagerWrapper>
    );
  };

  const renderPopover = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        flexWrap="nowrap"
        height="100%"
        sx={{
          "> *": {
            flex: "1 1 auto",
          },
        }}
      >
        <Box
          alignItems="center"
          component="header"
          display="flex"
          flex="0 0 auto"
          height="55px"
          width="100%"
          justifyContent="space-between"
          padding={mobile ? "0.5rem" : "1rem"}
          sx={{
            borderBottom: (theme) => {
              return `1px solid ${theme.palette.divider}`;
            },
            "> *": {
              flex: "1 1 auto",
            },
          }}
        >
          {mobile ? (
            <Box display="flex" flex="0 0 auto" marginRight="0.5rem">
              <Tooltip title="Close templates">
                <IconButton onClick={closeHandler}>
                  <ArrowBackIcon />
                </IconButton>
              </Tooltip>
            </Box>
          ) : null}
          <Typography variant={mobile ? "h5" : "h6"} color="text.primary">
            Templates
          </Typography>
          {hasManageOptions && (
            <Box display="flex" flex="0 0 auto" justifyContent="flex-end">
              <Tooltip title="Create/edit templates">
                <IconButton onClick={handleEditButtonClick} size="large">
                  <EditIcon data-testid="edit" />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
        {renderList()}
      </Box>
    );
  };

  return showManager ? renderManager() : renderPopover();
}

PropTypes.Root = {
  activeSavedReply: PropTypes.object, // passed
  currentAccount: PropTypes.object.isRequired, // withConnect
  currentUser: PropTypes.object.isRequired, // withConnect
  handleCloseModal: PropTypes.func, // passed
  handleEditButtonClick: PropTypes.func, // passed
  handleSavedReplySelected: PropTypes.func, // passed
  hasManageOptions: PropTypes.bool, // passed
  setActiveSavedReply: PropTypes.func, // passed
  settings: PropTypes.bool, // passed
  settingsAccount: PropTypes.object, // passed
  showManager: PropTypes.bool, // passed
};
