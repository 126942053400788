import { DataGridPro } from "@mui/x-data-grid-pro";
import { TextUsTableProps } from "./types";
import { useTextUsTable } from "./useTextUsTable";

/**
 * This component is a wrapper around the DataGridPro component from MUI.
 * It allows us to keep styling the same across all tables in the app,
 * while reducing the number of props we need to provide to render the component.
 *
 * @example
 * // Basic usage:
 * <TextUsTable
 *  collection={collection}
 *  columns={columns}
 *  selected={selected}
 *  setSelected={setSelected}
 *  setSelectedRecords={setSelectedRecords}
 * />
 */
export function TextUsTable({
  className,
  checkboxSelection = true,
  collection,
  columns,
  hideColumnsOnMobile,
  hideColumnsOnDesktop,
  hideColumnHeaders = false,
  initialState = {},
  noRowsOverlay,
  selected,
  setSelected,
  setSelectedRecords,
}: TextUsTableProps) {
  const {
    rows,
    rowCount,
    loading,
    paginationModel,
    columnVisibilityModel,
    onPaginationModelChange,
    onRowSelectionModelChange,
  } = useTextUsTable({
    collection,
    hideColumnsOnMobile,
    hideColumnsOnDesktop,
    setSelected,
    setSelectedRecords,
  });

  return (
    <DataGridPro
      columns={columns}
      checkboxSelection={checkboxSelection}
      className={className}
      columnHeaderHeight={56}
      columnVisibilityModel={columnVisibilityModel}
      disableColumnMenu
      disableRowSelectionOnClick
      disableVirtualization
      initialState={initialState}
      keepNonExistentRowsSelected
      loading={loading}
      onPaginationModelChange={onPaginationModelChange}
      onRowSelectionModelChange={onRowSelectionModelChange}
      pagination
      paginationMode="server"
      paginationModel={paginationModel}
      pageSizeOptions={[]}
      rows={rows}
      rowCount={rowCount}
      rowHeight={54}
      rowSelectionModel={selected}
      slots={{
        noRowsOverlay() {
          return noRowsOverlay;
        },
      }}
      slotProps={{
        baseCheckbox: {
          color: "secondary",
        },
      }}
      sx={{
        "& .MuiDataGrid-columnHeaderCheckbox": {
          backgroundColor:
            selected.length > 0 ? "customColors.outboundMessageColor" : "#fff",
        },
        "& .MuiDataGrid-columnHeaders": {
          display: hideColumnHeaders ? "none" : "flex",
        },
      }}
    />
  );
}
